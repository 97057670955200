import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import AllJudges from "../../components/AllJudges"
import Company from "../../assets/images/Film-Chronicle-Logo-Tertiary-Light.svg"
import Sponsors from "../../assets/images/gray-hands.svg"
import Judges from "../../assets/images/Peace-Dark.svg"
import Rules from "../../assets/images/Medal-Light.svg"
import Seo from "../../components/Seo"
import LoadMore from "../../assets/images/Load-more-arrow.svg"
import { Helmet } from "react-helmet"

const aboutJudges = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8"/>
        <title>Our Judges</title>
        <link rel="canonical" href="https://filmchronicle.com/about/judges" />
      </Helmet>
      <Seo title="Judges" description="We are students just like you&mdash;even our festival judges are enrolled students at schools across the country" image="https://www.datocms-assets.com/48645/1628907670-about-usjudges-hero.png" />
      <header className="hero">
        <StaticImage
          src="../../assets/images/About-Us__Judges-Hero.png"
          alt="flour and eggs"
          className="hero-img"
          placeholder="tracedSVG"
          layout="fullWidth"
        ></StaticImage>
        <div className="hero-container">
          <div className="hero-text">
            <h1 style={{marginBottom: "1.5rem"}}>Film Chronicle's Judges</h1>
            <p style={{color: "#fff", fontFamily: "Proxima Nova, sans-serif", fontWeight: "500", textTransform: "none", maxWidth: "40rem"}}>We are students just like you&mdash;even our festival judges are enrolled students at schools across the country</p>
          </div>
        </div>
      </header>
      <div className="container-md">
        <div className="grid-cols-4 center">
        <Link to="/about/company">
            <img
              src={Company}
              alt="flour and eggs"
              className="hero-img"
              style={{paddingBottom: "15px"}}
              // placeholder="tracedSVG"
            />
            <h3 style={{textAlign: "center"}}>COMPANY</h3>
          </Link>
        <Link to="/about/judges">
          <img
            src={Judges}
            alt="flour and eggs"
            className="hero-img"
            // placeholder="tracedSVG"
            style={{fontSize: "100px", paddingBottom: "15px"}}
          />
          <h3 style={{textAlign: "center"}}>JUDGES</h3>
          <div style={{paddingTop: "15px",borderBottom: "0.25rem solid red"}}>
              </div>
        </Link>
        <Link to="/about/rules">
          <img
            src={Rules}
            alt="flour and eggs"
            className="hero-img"
            // placeholder="tracedSVG"
            style={{fontSize: "100px", margin: "auto", paddingBottom: "15px"}}
          />
          <h3 style={{textAlign: "center"}}>RULES &amp; FAQs</h3>
        </Link>
        <Link to="/about/sponsors">
          <img
            src={Sponsors}
            alt="sponsors icon"
            className="hero-img"
            style={{fontSize: "100px", paddingBottom: "15px"}}
          />
          <h3 style={{textAlign: "center"}}>SPONSORS</h3>
        </Link>
        </div>
      </div>
      <AllJudges/>
      <div className="container">
        <div className="grid-cols-3">
          <p className="col-span-3" style={{textAlign: "center", marginBottom: "4rem", display: "none"}}>No more results</p>
            <a style={{gridColumnStart: "6"}} href="#top">
              <img
                src={LoadMore}
                alt="flour and eggs"
                placeholder="tracedSVG"
                layout="constrained"
              />
            </a>
        </div>
      </div>
    </Layout>
  )
}

export default aboutJudges
